@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --black: #222029;
  --purple:#3e0b80;
  --red:#d11f1f;
  --green: #00ff08;
  --orange: #e73803;
} 
*{
  box-sizing: border-box;
}
.App {
  text-align: center;
  position: relative;
  background: #100e17;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .App header{
  position: absolute;
  background: rgba(0, 0,0, 0.6);
  top:0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
