.board {
    height: 700px;
    left:0;
    position: absolute;
    top: 0;
    z-index: 10;
    right:0;
    height: 100vh;
    border-radius: 40px;
    padding: 20px;
    display: flex;
    align-items: center;
    opacity: 0;
}
.board.show {
    opacity: 1;
    transition: all .3s ease;
}
.offset{
    width: 33%;
}
.inner {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 0 100px;
    height: 55%;
    text-align: center;
    background: linear-gradient(180deg, rgb(49, 47, 69), var(--black));
    background: #1b1124;
}

.title {
    font-size: 54px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 30px;
    /* text-shadow: 0 0 100px #000; */
    /* text-shadow: 0 -5px 1px rgb(150, 1, 1), 0 5px 1px rgb(150, 1, 1),
        5px 0 1px rgb(150, 1, 1), -5px 0 1px rgb(150, 1, 1); */
}

.text {
    color: #fff;
    color:yellow;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.8;
    /* text-shadow: 0 0 100px #000; */
}
.text.second{
    /* color: #f733ea; */
}

