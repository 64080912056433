.wrapper{
    color: #fff;
    display: flex;
    align-items: center;
    font-family:"Poppins", sans-serif;
    padding-left: 80px;
    height: 44px;
}

.wrapper path{
    fill: #fff !important;
}

.wrapper span{
    font-family:"Poppins", sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 1;
}