.wrapper{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999;
    animation: rockIn 0.2s both;
}
.shadow{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1;
    
    background-size: cover;
    background-image: url(../../../public/images/asteroid-bg.png);
    /* background: radial-gradient(rgba(0,0,0,0.75), rgba(0,0,0,0.2)); */
}
/* .glass{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 2;
    transform: scale(1.2);
    background-size: cover;
    background-image: url(../../../public/images/broken-glass.png);
    animation: rockIn 0.1s both;
} */
 
.details{
    z-index: 3;
}
.title{
    color: #fff;
    font-size: 160px;
    font-weight: 800;
    text-shadow: 0 5px 20px #000;
}

.value{
    color: yellow;
    font-size: 160px;
    margin-top: -20px;
    font-weight: 800;
    text-shadow: 0 5px 20px #000, 1px 2px 25px yellow;
    animation: valueIn both 1.6s linear;
    animation-delay: .1s;
}
.location{
    color: #fff;
    font-size: 50px;
    font-weight: 600;
}
.round span{
    color: #fff;
}
.code span{
    color: #fff;
}
.bottom{
    display: flex;
    justify-content: center;
    gap:100px;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    color: #7e7e7e;
}
@keyframes valueIn {
    0%{
        transform: scale(1.1);
    }
    15%{
        transform: scale(1.7);
    }
    30%{
        transform: scale(0.8);
    }
    45%{
        transform: scale(1.3);
    }
    55%{
        transform: none;
    }
}
@keyframes rockIn {
    0%{
        transform: scale(1);
    }
    30%{
        transform: scale(1.05);
    }
    55%{
        transform: none;
    }
}
.lights{
    /* position: absolute; */

}
/* Basic setup for green lights */
.light {
    position: absolute;
    z-index: 100;
    width: 6px;
    height: 6px;
    background: rgba(0, 255, 0, 0.9);
    border-radius: 50%;
    box-shadow: 0 0 70px 30px rgba(0, 255, 0, 1);
    animation: pulseLight 6s linear infinite both;
  }
  
  /* Smooth intensity animation */
  @keyframes pulseLight {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.75;
    }
    100%{
        opacity: 0;
    }
  }