.wrapper{
    position: absolute;
    top:30%;
    left: 50vw;
    color: #fff;
    font-size: 20rem;
    font-weight: 700;
    opacity: 0;
}

.wrapper.show{
    opacity: 1;
}