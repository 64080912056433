/* WINS */
.topWinsWrapper{
    position: absolute;
    left: 0;
    top: 200px;
    padding-top: 50px;
    z-index: 9999;
    display: flex;
    align-items: stretch;
    gap:30px;
}
.title{
    color: rgba(255, 255, 255, 0.718);
    font-size: 30px;
    font-weight: 600;
    writing-mode: vertical-rl; /* or vertical-lr for the opposite direction */
    transform: rotate(180deg); /* Optional, rotates the text 180 degrees if needed */
    box-sizing: border-box;
}

.topWins{
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
}
.bar::after{
    content:'';
    display: block;
    border-left:80px solid var(--black);
    border-bottom:40px solid transparent;
    position: absolute;
    bottom: -40px;
}
.bar::before{
    content:'';
    display: block;
    border-left:80px solid var(--black);
    border-top:40px solid transparent;
    position: absolute;
    top: -40px;
}
.bar{
    width: 80px;
    /* height: 100%; */
    background: var(--black);
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #00ff08c8;
    /* box-shadow: 10px 0 120px #00ff08ba; */
    box-shadow: 10px 0 150px #00ff0800;
    position: relative;
    z-index: 10;
    opacity: 0;
    transform: translateX(-150px);
    transition: transform .3s ease-in;
    animation: lighting 2s ease both;
    animation-delay: 1.8s;
}
.show .bar{
    transform: none;
    opacity: 1;
    /* box-shadow: 10px 0 150px #00ff0865; */
}
.win {
    /* background: linear-gradient(0deg, #672626, #ff2f2f); */
    margin-bottom: 20px;
    padding: 10px 25px;
    border-radius: 10px;
    /* width: 240px; */
    background: rgba(0, 128, 0, 0.375);
    background: radial-gradient(rgba(0, 128, 0, 0.15), rgba(0, 128, 0, 0.375));
    box-shadow: 0 0 150px 15px rgba(0, 128, 0, 0.375);
    border-left: 1px solid #00ff0829;
    text-align: left;
    transform: translateX(600px);
    opacity: 0;
}
.show .win{
    animation: winEntry 1s ease;
    animation-fill-mode: both;
    animation-delay: 5s;
}
.win:nth-child(1){
    font-size: 42px; 
}
.win:nth-child(2){
    font-size: 39px; 
}
.win:nth-child(3){
    /* width: 280px; */
    font-size: 30px; 
}
.win:nth-child(4){
    font-size: 27px; 
}
.win:nth-child(5){
    font-size: 22px; 
    margin-bottom: 0;
}

.value {
    /* font-size: 35px; */
    font-weight: 600;
    color: #00ff08;
    color: #fff;
    white-space: nowrap;
    text-shadow: 0 1px 15px #0005;
}

.date{
    color: rgba(181, 234, 178, 0.5);
    font-size: 16px;
}


@keyframes winEntry {
    from{
        transform: translateX(-100%);
        opacity: 0;
    }
    to{
        transform: none;
        opacity: 1;
    }
}

@keyframes lights {
    from{
        transform: translateX(-100%);
        opacity: 0;
    }
    to{
        transform: none;
        opacity: 1;
    }
}

@keyframes lighting {
    0%{
        box-shadow: 10px 0 150px #00ff0865;
    }
    50%{
        box-shadow: 10px 0 150px #00ff08bc;
    }
    100%{
        box-shadow: 10px 0 150px #00ff0865;
    }
}