.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 1s ease;
}

.header.hidden{
    /* top:-100px; */
}

.center img{
    height: 64px;
}
.jackpotWrapper{
    height: 80px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:5px;
}
.jackpot {
    border-radius: 20px;
    color: #fff;
    font-size: 44px;
    font-weight: 800;
    line-height: 1;
}
.jackpot.global{
    border-color: rgb(249, 249, 33);
}
.jackpotTitle{
    padding: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    padding-bottom: 0;
}

.jackpotTitle.global{
    color: rgb(251, 251, 55);
}
.jackpotTitle.local{
    color: rgb(188, 188, 188);
}

.left{
    background: var(--black);
    padding: 10px;
    display: flex;
    position: absolute;
    height: 100px;
    width: 360px;
    background: repeating-linear-gradient(70deg,var(--black), rgb(49, 47, 69),rgb(45, 43, 60) ,var(--black),var(--black) );
}

.left::after{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-left:50px solid var(--black);
    border-top: 100px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}
.right{
    background: var(--black);
    padding: 10px;
    display: flex;
    position: absolute;
    height: 100px;
    width: 360px;
    background: repeating-linear-gradient(140deg,var(--black), var(--black),rgb(49, 47, 69),rgb(45, 43, 60),var(--black) );
}

.right::after{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-right:50px solid var(--black);
    border-top: 100px solid transparent;
    position: absolute;
    left: -50px;
    top: 0;
}


.leftSecond{
    background: var(--red);
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100px;
    width: 260px;
    gap:5px;
}

.leftSecond::before{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-right:50px solid var(--red);
    border-bottom: 100px solid transparent;
    position: absolute;
    left: -50px;
    top: 0;
}
.leftSecond::after{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-left:50px solid var(--red);
    border-top: 100px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}
.nextRoundTitle, .leftSecond .nextRoundNo{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}
.leftSecond .nextRoundNo{
    font-size: 44px;
}

.rightSecond{
    background: var(--red);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:5px;
    position: absolute;
    height: 100px;
    width: 260px;
}

.rightSecond::before{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-left:50px solid var(--red);
    border-bottom: 100px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}
.rightSecond::after{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-right:50px solid var(--red);
    border-top: 100px solid transparent;
    position: absolute;
    left: -50px;
    top: 0;
}

.center{
    position: absolute;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);
    background: repeating-linear-gradient(140deg,var(--black), var(--black), rgb(49, 47, 69),rgb(45, 43, 60) ,var(--black),var(--black) );
    width: 430px;
    left:50%;
    margin-left:-215px;
}
.center::before{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-left:50px solid var(--black);
    border-bottom: 100px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}
.center::after{
    display: block;
    content: '';
    /* border-top:60px solid #fff; */
    border-right:50px solid var(--black);
    border-bottom: 100px solid transparent;
    position: absolute;
    left: -50px;
    top: 0;
}


/* ANIMATIONS */
.center.show{
    top:0;
    transition: all 1s ease;
}

.center{
    top:-120px;
    transition: all 1s ease 0.8s;
}

/* left */
.left.show{
    top: 0;
    left: 0;
    transition: all 1s ease 0.8s;
}
.left{
    top:-120px;
    left: -50px;
    transition: all 1s ease;
}

/* right */
.right.show{
    top: 0;
    right: 0;
    transition: all 1s ease 0.8s;
}
.right{
    top:-120px;
    right: -50px;
    transition: all 1s ease;
}

/* left second */
.leftSecond.show{
    top: 0;
    left: 420px;
    transition: all 1s ease .4s;
}
.leftSecond{
    top:-120px;
    left: 370px;
    transition: all 1s ease .4s;
}

/* right second */
.rightSecond.show{
    top: 0;
    right: 420px;
    transition: all 1s ease .4s;
}
.rightSecond{
    top:-120px;
    right: 370px;
    transition: all 1s ease .4s;
}