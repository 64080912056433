.wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    gap: 10px;
    padding: 10px;
}

.round {
    flex: 1;
    border-radius: 10px;
    background: var(--black);
    /* box-shadow: 0 5px 13px 1px #00000070; */
    box-shadow: 0 2px 0 #6e6e6e;
    animation: historyAnimation 0.3s ease-in-out both;
}

.hidden .round {
    animation: hideHistoryAnimation 0.3s ease-in-out both;
}

.value {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 5px 5px;
}

.title {
    color: #fff;
    font-size: 12px;
    background: var(--orange);
    height: 26px;
    line-height: 1;
    font-weight: 600;
    padding-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.number {
    color: #b0b0b0;
    padding: 5px 2px;
    font-size: 15px;
}

@keyframes historyAnimation {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: none;
        opacity: 1;
    }
}
@keyframes hideHistoryAnimation {
    0% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translateY(50px);
        opacity: 0;
    }
}

/* COLORS */
.gray .value {
    color: #ccc;
}
.yellow .value {
    color: yellow;
}
.yellow {
    box-shadow: 0 2px 0 yellow;
}
.green .value {
    color: var(--green);
}
.green {
    box-shadow: 0 2px 0 var(--green);
}
.blue .value {
    color: #2dcef2;
}
.blue {
    box-shadow: 0 2px 0 #2dcef2;
}
.purple .value {
    color: #f733ea;
}
.purple {
    box-shadow: 0 2px 0 #f733ea;
}
