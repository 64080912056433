.wrapper{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 10000;
    background: linear-gradient(0, #000, #ccc);
}

.wrapper.hidden{
    display: none;
}

.left{
    background: repeating-linear-gradient(45deg,  var(--black), #333232,  var(--black));
    position: absolute;
    left: 0;
    width: 50%;
    height: 200vh;
}
.right{
    background: repeating-linear-gradient(45deg,  var(--black), #383737,  var(--black));
    right: 0;
    width: 50%;
    height: 200vh;
    position: absolute;
    top:0;
}

.center{
    position: absolute;
    left: -200px;
    width: 400px;
    top:25%;
    margin-top:-200px;
    height: 400px;
    background: var(--black);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.backCenter{
    animation: rotating 4s linear both  infinite;
    background: rgb(199, 3, 3);
    box-shadow: 0 0 15px 10px rgb(200, 3, 3, .2);
}
.center h1{
    text-align: center;
    padding: 0 15px;
}

@keyframes rotating {
    from{
        
    }
    to{
        transform: rotateZ(360deg);
    }
}