.wrapper {
    position: absolute;
    top: 35%;
    left: 17%;
    color: #892bd3;
    /* text-shadow: 10px -6px red; */
    font-size: 18rem;
    font-weight: 700;
    opacity: 0;
    /* transition: opacity .7s ease; */
}

.wrapper.show {
    opacity: 1;
}

.wrapper.center{
    top:35%;
    left: 50%;
    transition: all 1.3s ;
    transform: translateX(-50%) scale(1.1);
}

/* COLORS */
.gray {
    color: #ccc;
}
.yellow {
    color: yellow;
}
.green {
    color: var(--green);  
}
.blue {
    color: #2dcef2;
    left:13%;
}
.purple {
    color: #f733ea;
    left:10%;
}
