.round{
    position: absolute;
    left: 50px;
    top:30px;
    z-index: 80;
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
    opacity: 0;
   
}

.round.show{
    opacity: 1;
}


.round.center{
    top:30%;
    left: 50%;
    transition: all 1.8s ease-in;
    transform: translateX(-50%);
}